import React from 'react';
import Careers from 'views/Careers';
import { SEO as Head } from 'components/seo';

const CareersPage = (): JSX.Element => {
  const seo = {
    title: 'Unleash Your Potential with Coreestate Careers',
    description:
      'Be a part of our global vision to build rental properties worldwide. Make a meaningful impact and shape the future of real estate. We offer job opportunities for those who have experience in digital share offerings, real estate industry and those who are looking to kickstart career in blockchain.',
    pathname: '/careers',
  };
  return (
    <>
      <Head
        title={seo.title}
        description={seo.description}
        pathname={seo.pathname}
      />
      <Careers />
    </>
  );
};

export default CareersPage;
