import React from 'react';
import Main from 'layouts/Main';
import { CareersSection } from './components';
import { useTheme } from '@mui/material/styles';

const CareersView = (): JSX.Element => {
  const theme = useTheme();

  return (
    <Main bgcolor={theme.palette.background.level1} sectionTitle="Careers">
      <CareersSection />
    </Main>
  );
};

export default CareersView;
