import React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/system';
import { StyledPropsFix } from 'types';
import { useTheme } from '@mui/material/styles';
import { Link, useMediaQuery } from '@mui/material';
import CareersPic01 from 'images/CareersSection/career_01.svg';
import { Divider } from 'utils';
import { SiteMaxWithContext } from 'theme/siteMaxWithContext';

const ContentBox = styled(Container)``;
const TextSectionBox = styled(Box)``;
const PictureSectionBox = styled(Box)``;
const SubTitle = styled(Typography)`` as StyledPropsFix;
const Text = styled(Typography)`` as StyledPropsFix;
const ProjectLink = styled(Link)``;

const CareersPic = styled(Box)<{ src: string }>`
  width: 236px;
  height: 298px;
  background-image: url(${({ src }) => src});
`;

const CareersSectionStyles = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  line-height: 34px !important;

  .careers-contentBox {
    display: flex;
    align-items: space-between;
    padding: 0 1em;
    padding-right: 5em;

    ${({ theme }) => theme.breakpoints.down('md')} {
      padding: 0 2em;
      margin: 0;
    }
    ${({ theme }) => theme.breakpoints.only('xs')} {
      flex-direction: column-reverse;
      align-items: center;
    }
  }

  .careers-subTitle {
    font-family: Roboto Bold;
    text-align: left;
    line-height: 34px;
    color: ${({ theme }) => theme.palette.text.primary};

    &:nth-of-type(odd) {
      padding-bottom: 0.5em;
    }

    font-size: 1.5em;

    ${({ theme }) => theme.breakpoints.down('md')} {
      padding-bottom: 0;
      font-size: 1.2em;
    }
    ${({ theme }) => theme.breakpoints.only('xs')} {
      line-height: 28px;
    }
  }
  .careers-text {
    font-family: Roboto Bold;
    color: ${({ theme }) => theme.palette.text.primary};
    text-align: left;
    font-size: 1.1em;
    padding-bottom: 1em;
    padding-right: 4em;
    line-height: 34px;

    ${({ theme }) => theme.breakpoints.down('md')} {
      font-size: 1em;
      padding-top: 1em;
    }

    ${({ theme }) => theme.breakpoints.only('xs')} {
      line-height: 28px;
      padding-right: 0;
    }
  }
  .careers-link {
    font-family: Roboto Bold;
    white-space: nowrap;
    display: inline-block;
    font-size: 1em;
  }
  .careers-picture {
    margin-left: 4em;
    margin-top: -6em;

    ${({ theme }) => theme.breakpoints.down('md')} {
      margin-top: -2em;
    }
    ${({ theme }) => theme.breakpoints.only('xs')} {
      margin: 0;
      margin-bottom: 2em;
    }
  }
`;

const CareersSection = (): JSX.Element => {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.only('xs'), {
    defaultMatches: true,
  });
  const [sizeOfGrid] = React.useContext(SiteMaxWithContext);

  return (
    <CareersSectionStyles>
      <Box
        sx={{ backgroundColor: theme.palette.background.paper }}
        maxWidth="100%"
        display="flex"
        flexDirection="column"
        justifyContent="center"
      >
        <ContentBox
          className="careers-contentBox"
          sx={{ maxWidth: `${sizeOfGrid}px` }}
        >
          <TextSectionBox>
            <SubTitle component="h4" className="careers-subTitle">
              Our vision is to build rental properties worldwide.
            </SubTitle>
            <SubTitle component="h4" className="careers-subTitle">
              Join us and let&apos;s create impact on a global scale!
            </SubTitle>
            <Divider height={isXs ? '1em' : '2.3em'} />
            <Text component="p" className="careers-text">
              If you wish to move mountains together, please tell us about
              yourself and send a resume to{' '}
              <ProjectLink
                href="mailto:job@coreestate.io"
                className="careers-link"
              >
                job@coreestate.io
              </ProjectLink>
            </Text>
          </TextSectionBox>
          <PictureSectionBox>
            <CareersPic src={CareersPic01} className="careers-picture" />
          </PictureSectionBox>
        </ContentBox>
        <Divider height={isXs ? '3.3em' : '6.3em'} />
      </Box>
    </CareersSectionStyles>
  );
};

export default CareersSection;
